<template>
  <v-container
    fluid
    tag="section"
    class="mobile-editor"
    :class="{ mobileWidth: mobileSize }"
  >
    <div v-if="!getLoading" class="mobile-editor__container">
      <div class="mobile-editor__tools elevation-6">
        <editor-tool />
      </div>
      <div class="mobile-editor__preview">
        <div class="mobile-editor__preview--content">
          <div class="mobile-editor__preview--content---mobile">
            <mobile-preview />
          </div>
        </div>
      </div>
    </div>
    <v-row v-if="getLoading" no-gutters class="mobile-editor__loading">
      <v-col cols="12">
        <v-progress-circular indeterminate color="primary" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";

export default {
  name: "MobileEditor",
  components: {
    EditorTool: () => import("@/components/editor/components/EditorTool.vue"),
    MobilePreview: () =>
      import("./components/mobile-preview/MobilePreview.vue"),
  },
  data() {
    return {
      loading: null,
    };
  },
  computed: {
    ...mapGetters({
      getLoading: "mobileEditor/getLoading",
    }),
    mobileSize() {
      const mobileBreakpoint = 960;
      return this.$vuetify.breakpoint.width <= mobileBreakpoint;
    },
  },
  watch: {
    getLoading() {
      this.clearIntervalHandle();
    },
  },
  mounted() {
    this.loading = setInterval(() => {
      this.fetchData(this.$route.params.id);
    }, 5000);
  },
  beforeDestroy() {
    this.resetEditor();
    this.clearIntervalHandle();
  },
  methods: {
    ...mapMutations({
      resetEditor: "mobileEditor/RESET_STATE",
    }),
    ...mapActions({
      fetchData: "mobileEditor/fetchData",
    }),
    clearIntervalHandle() {
      clearInterval(this.loading);
      this.loading = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.mobile-editor {
  padding: 0;
  height: 100%;
  background-color: var(--v-mobile_editor_background-base);
}

.mobile-editor__loading {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;

  .col {
    display: flex;
    justify-content: center;
  }
}

.mobile-editor__container {
  height: 100%;
  display: flex;
  flex-direction: row;
}

.mobile-editor__tools {
  height: 100vh;
  width: 450px;
  min-width: 450px;
  max-width: 450px;
  z-index: 1;
}

.mobileWidth {
  overflow: scroll;

  .mobile-editor__preview--content {
    width: 620px;
  }
}

.mobile-editor__preview {
  height: 100%;
  width: 100%;
}

.mobile-editor__preview--content {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: var(--v-mobile_editor_background-base);
}

.mobile-editor__preview--content---mobile {
  height: 100%;
  overflow: scroll;
}
</style>
